import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './styles.css'

// Initialize Swiper core components
SwiperCore.use([Navigation]);

const testimonialStyles = {
    testimonial: {
        position: 'relative',
    },
    testimonialCard: {
        border: '1px solid #ccc',
        borderRadius: '50px',
        padding: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '80%', // Adjusted to 85%
        textAlign: 'center',
        margin: '0 auto',
        borderLeft: 'none',
        borderRight: 'none',
        marginTop: '2em',
    },
    testimonialImg: {
        maxWidth: '60px', // Adjusted maximum width
        maxHeight: '60px', // Adjusted maximum height
        borderRadius: '50%',

    },
    reviewText: {
        fontSize: '1.4em', // Increased font size for review text
    },
};

const additionalStyles = `
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  /* display: block; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 100%;
}

`;

const Testimonial = ({ testimonials }) => {
    return (
        <>
            <style>{additionalStyles}</style>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <div style={testimonialStyles.testimonial} className="testimonial">
                            <div style={testimonialStyles.testimonialCard} className="testimonial-card">
                                <div className="testimonial-content">
                                    <p className="testimonial-review" style={testimonialStyles.reviewText}>
                                        <em>&quot;{testimonial.review}&quot;</em>
                                    </p>
                                    <hr />
                                    <div>
                                        <img src={testimonial.imgSrc} alt={testimonial.name} style={testimonialStyles.testimonialImg} className="testimonial-img" />
                                    </div>
                                    <div>
                                        <h3 className="testimonial-name">{testimonial.name}</h3>
                                        <a target='_blank' href="https://www.tripadvisor.com/Attraction_Review-g189172-d25295700-Reviews-PinkMyBike-Braganca_Braganca_District_Northern_Portugal.html">
                                            {testimonial.company}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

Testimonial.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            review: PropTypes.string.isRequired,
            company: PropTypes.string.isRequired,
            imgSrc: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Testimonial;
