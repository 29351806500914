import { Wrapper, TextBox, Caption, Title, Subtitle, Video, VideoDiv } from './styles';

export const VideoHero = ({
  caption,
  title,
  subtitle,
  videourl,
  button,
  sectionChildren,
  isFullViewport,
}) => (

  <Wrapper isFullViewport={isFullViewport}>
    <VideoDiv dangerouslySetInnerHTML={{
      __html:`
      <Video
        style="position: relative; width:100%; aspect-ratio: 560 / 320"
        autoplay
        muted
        loop
        poster="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjAAAAFAAQMAAACY9dRwAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAdhwAAHYcBj+XxZQAAAANQTFRF/gGaEeEoGwAAACxJREFUGBntwTEBAAAAwiD7p95vBmAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJ1jAAAG6Sc1JAAAAAElFTkSuQmCC"
        playsinline
        src="${videourl}"
        type="video/mp4"
      >
      </Video>`
    }}
    />
      {/* <TextBox>
        {caption && <Caption>{caption}</Caption>}
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {button}
      </TextBox> */}
    {/* {sectionChildren} */}
  </Wrapper>
);
